import { Component, ReactNode } from 'react'
import copyError from 'utils-copy-error'
import { captureException, withScope } from 'common/sentry'
import { BannerNotification, BannerType } from 'components/molecules'
import { teams } from '../../../teams'

export type Owner = keyof typeof teams

interface ErrorBoundaryCommonProps {
  owner: Owner
  feature: string
  children: ReactNode
}

export interface ErrorBoundaryProps extends ErrorBoundaryCommonProps {
  header?: ReactNode
  message: ReactNode
  bannerType?: BannerType
}

export interface ErrorBoundaryWithCustomElementProps extends ErrorBoundaryCommonProps {
  errorElementToRender: ReactNode
}

interface ErrorBoundaryState {
  error: boolean
}

class ErrorBoundary extends Component<
  ErrorBoundaryProps | ErrorBoundaryWithCustomElementProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError() {
    return { error: true }
  }

  state = {
    error: false,
  }

  componentDidCatch(error: Error) {
    // This is a hack to work around a limitation of Sentry.
    //
    // Sentry captures errors via multiple methods: wrapping `window.onerror`, wrapping calls to requestAnimationFrame in a try/catch, etc.
    // This can lead to Sentry capturing the same error multiple times, via different strategies.
    // To prevent reporting the same error multiple time, Sentry attachs a `__sentry_captured__` property to captured errors.
    // Sentry does not report errors that already have a `__sentry_captured__` property set.
    //
    // Here in our ErrorBoundary, its highly likely that Sentry has already captured & reported this error.
    // Our Sentry setup is configured to use the GlobalHandlers integration, which wraps `window.onerror`.
    // When a React component throws an error, the `window.onerror` callback is executed BEFORE the ErrorBoundary componentDidCatch handler.
    // Therefore, calling `captureException` here will be a no-op. The error has already been reported, without any useful tags attached.
    // We work around this limitation by cloning the error (without the `__sentry_captured__` field`), and attaching some useful tags.
    // This means the same error will be reported to Sentry twice. IMO this is a valid tradeoff for having Sentry errors correctly labelled.
    const clonedError = copyError(error)

    withScope(scope => {
      scope.setTag('owner', teams[this.props.owner])
      scope.setTag('feature', this.props.feature)
      captureException('ErrorBoundary', clonedError)
    })
  }

  render() {
    let errorElement
    if ('errorElementToRender' in this.props) {
      errorElement = this.props.errorElementToRender
    } else {
      errorElement = (
        <BannerNotification
          type={this.props?.bannerType || BannerType.WARNING}
          message={{ header: this.props?.header, message: this.props.message }}
        />
      )
    }

    if (this.state.error) {
      return errorElement
    }

    return this.props.children
  }
}

export default ErrorBoundary
