// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActions } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActions'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActions'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeProperties } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeProperties'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativePropertiesFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativePropertiesFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativePropertiesToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeProperties'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
 */
export interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative {
  /**
   * The id of the creative. Only needed for update
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
   */
  id?: string
  /**
   * The type of placement the creative will be placed in
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
   */
  type: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeTypeEnum
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeProperties}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
   */
  properties?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeProperties
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActions}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
   */
  actions?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActions
  /**
   * Product IDs that are used for view through attribution
   * @type {Array<string>}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
   */
  trackedProductIds?: Array<string>
  /**
   * Read only field indicating whether creative is using new view through attribution
   * @type {boolean}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
   */
  useNewAttribution?: boolean
  /**
   * Product Group IDs that are used for view through attribution based on product group
   * @type {Array<string>}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
   */
  trackedProductGroupIds?: Array<string>
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
   */
  assets: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
}

/**
 * @export
 */
export const GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeTypeEnum = {
  CaperImageBannerV1: 'caper_image_banner.v1',
  CaperSpotlightAdPrototypeV1: 'caper_spotlight_ad.prototype.v1',
  EmailV1: 'email.v1',
  ImageBannerV2: 'image_banner.v2',
  ImageBannerV3: 'image_banner.v3',
  MnmVideoPrototypeV1: 'mnm.video.prototype.v1',
  OccasionV1: 'occasion.v1',
  PromotedAisleV1: 'promoted_aisle.v1',
  PromotedAisleV2: 'promoted_aisle.v2',
  PromotedAisleV3: 'promoted_aisle.v3',
  PromotedAisleV4: 'promoted_aisle.v4',
  SearchBannerV1: 'search_banner.v1',
  ShoppableVideoV1: 'shoppable_video.v1',
  ShoppableVideoV2: 'shoppable_video.v2',
  ShoppableVideoV3: 'shoppable_video.v3',
  SmartV1: 'smart.v1',
  SponsoredRecipeV1: 'sponsored_recipe.v1',
  SponsoredRecipeVideoV1: 'sponsored_recipe_video.v1',
  UvcBannerV1: 'uvc_banner.v1',
  UvcBannerV2: 'uvc_banner.v2',
} as const
export type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeTypeEnum =
  typeof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeTypeEnum[keyof typeof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeTypeEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative interface.
 */
export function instanceOfGetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative {
  return GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    type: json['type'],
    properties: !exists(json, 'properties')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativePropertiesFromJSON(
          json['properties']
        ),
    actions: !exists(json, 'actions')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsFromJSON(
          json['actions']
        ),
    trackedProductIds: !exists(json, 'tracked_product_ids')
      ? undefined
      : json['tracked_product_ids'],
    useNewAttribution: !exists(json, 'use_new_attribution')
      ? undefined
      : json['use_new_attribution'],
    trackedProductGroupIds: !exists(json, 'tracked_product_group_ids')
      ? undefined
      : json['tracked_product_group_ids'],
    assets: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSON(
      json['assets']
    ),
  }
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    properties: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativePropertiesToJSON(
      value.properties
    ),
    actions: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeActionsToJSON(
      value.actions
    ),
    tracked_product_ids: value.trackedProductIds,
    use_new_attribution: value.useNewAttribution,
    tracked_product_group_ids: value.trackedProductGroupIds,
    assets: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsToJSON(
      value.assets
    ),
  }
}
