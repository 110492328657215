import { TimeFrame } from 'common/types'
import { object, SchemaType, string, mixed, array, boolean, number } from 'common/validation'
import { MetricConfig, ChartDataPayload } from 'components/Reporting'
import { FlipperNUXs } from 'components/Reporting/components/NUX/NUXFlipperHintProvider'
import {
  ApiBrandPagesControllerCreateInputBrandPageTypeEnum as TypeEnum,
  ApiBrandPagesControllerCreateInputBrandPageLevelEnum as LevelEnum,
} from 'service/openapi/__codegen__/models/ApiBrandPagesControllerCreateInputBrandPage'
import { GetAdminBrandPagesIdParamBlockBrandLogoAssets } from 'service/openapi/__codegen__/models/GetAdminBrandPagesIdParamBlockBrandLogoAssets'
import { GetAdminBrandPagesIdParamBlockHeroBannerAssets } from 'service/openapi/__codegen__/models/GetAdminBrandPagesIdParamBlockHeroBannerAssets'
import { GetAdminBrandPagesIdParamBlockImageAndTextContent } from 'service/openapi/__codegen__/models/GetAdminBrandPagesIdParamBlockImageAndTextContent'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import { PostBrandPagesIdStatusParamAssetFieldDecisionsAltText } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamAssetFieldDecisionsAltText'
import { PostBrandPagesIdStatusParamAssetFieldDecisionsImage } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamAssetFieldDecisionsImage'
import { PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues'
import { PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent'
import { PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValues } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValues'
import { PostBrandPagesIdStatusParamBrandPageFieldDecisionsHierarchy } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamBrandPageFieldDecisionsHierarchy'
import { PostBrandPagesIdStatusParamBrandPageFieldDecisionsTaxonomyValues } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamBrandPageFieldDecisionsTaxonomyValues'
import { PostBrandPagesIdStatusParamProductAssetFieldDecisionsAltText } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamProductAssetFieldDecisionsAltText'
import { PostBrandPagesIdStatusParamProductAssetFieldDecisionsImage } from 'service/openapi/__codegen__/models/PostBrandPagesIdStatusParamProductAssetFieldDecisionsImage'
import { StandaloneBrandPageResponseDataAttributesStatusEnum as StatusEnum } from 'service/openapi/__codegen__/models/StandaloneBrandPageResponseDataAttributes'

export {
  ApiBrandPagesControllerCreateInputBrandPageLevelEnum as LevelEnum,
  ApiBrandPagesControllerCreateInputBrandPageTypeEnum as TypeEnum,
} from 'service/openapi/__codegen__/models/ApiBrandPagesControllerCreateInputBrandPage'

export { StandaloneBrandPageResponseDataAttributesStatusEnum as StatusEnum } from 'service/openapi/__codegen__/models/StandaloneBrandPageResponseDataAttributes'

type UrlTypes = {
  previewUrl: string
  uploadedUri: string
}

type Asset = {
  urls: UrlTypes
  id: string
}

type ContentType = {
  title: string
}

type AssetType = {
  alt: string
  mobile: Asset
  desktop: Asset
}

// eslint-disable-next-line no-restricted-syntax
export enum DurationEnum {
  Evergreen = 'evergreen',
  Seasonal = 'seasonal',
}

type ProductType = string[]

/*
  ===========
  Block types
  ===========
*/
type ProductAsset = {
  [key: string]: AssetType
}

export type BannerType = {
  // Hero & in page banner
  type: string
  assets: AssetType
  content?: never
  productIds?: never
  productAssets?: never
  id?: string
}

export type SmallCarouselType = {
  type: string
  content: ContentType
  productIds: ProductType
  assets?: never
  productAssets?: never
  id?: string
}

export type ProductGroupCarouselType = {
  type: string
  content: ContentType
  productIds: never
  productGroupIds: ProductType
  assets?: never
  productAssets?: never
  id?: string
}

export type LargeCarouselType = {
  type: string
  content: ContentType
  productIds: ProductType
  productAssets?: ProductAsset
  assets?: never
  id?: string
}

export type LogoType = {
  type: string
  assets: {
    alt: string
    urls: UrlTypes
    id: string
  }
  content?: never
  productIds?: never
  productAssets?: never
  id?: string
}

export type ImageTextType = {
  type: string
  content: {
    title: string
    text: string
  }
  assets: AssetType
  productIds?: never
  productAssets?: never
  id?: string
}

export type BlockType =
  | BannerType
  | LogoType
  | SmallCarouselType
  | LargeCarouselType
  | ProductGroupCarouselType
  | ImageTextType

export type BlocksType = BlockType[]

const brandHierarchyInput = string().min(1).max(30).default('')

const linkedBrandSchema = object().shape({
  entityId: number().required(),
  reportingLevel1: string().required().default(''),
  reportingLevel2: string().required().default(''),
  reportingBrand: string().required().default(''),
})

export const brandPageFormSchema = object({
  id: string().default(''),
  name: string().default('').min(3).max(50),
  duration: mixed<DurationEnum>()
    .required()
    .oneOf(Object.values(DurationEnum))
    .default(DurationEnum.Evergreen),
  level: mixed<LevelEnum>().required().oneOf(Object.values(LevelEnum)).default(LevelEnum.Brand),
  type: string().required().default(TypeEnum.EvergreenV1),
  enabled: boolean().required().default(true),
  status: mixed<StatusEnum>().required().oneOf(Object.values(StatusEnum)).default(StatusEnum.Draft),
  hierarchy: array().of(brandHierarchyInput).required().default([]),
  blocks: mixed<BlocksType>(),
  linkedBrands: array().of(linkedBrandSchema).required().default([]),
})

export type BrandPageFormData = SchemaType<typeof brandPageFormSchema>
export type LinkedBrandPageFormData = SchemaType<typeof linkedBrandSchema>

export interface BrandPageFilterState {
  timeFrame: TimeFrame
  searchQuery: string
  nuxs?: FlipperNUXs[]
}

// eslint-disable-next-line no-restricted-syntax
export enum ChartUnitValues {
  PAGE_VIEWS = 'page_views',
  ITEM_DETAIL_PAGE_VIEWS = 'item_detail_page_views',
  ADD_TO_CARTS = 'add_to_carts',
  SALES = 'sales',
}

export type ChartUnit =
  | ChartUnitValues.PAGE_VIEWS
  | ChartUnitValues.ITEM_DETAIL_PAGE_VIEWS
  | ChartUnitValues.ADD_TO_CARTS
  | ChartUnitValues.SALES

export interface ChartSectionProps<T extends ChartUnitValues, F = void> {
  chartColor?: string
  filterState: F
  availableMetrics: MetricConfig<T>[]
  loadAggregatedMetrics(filterState: F): Promise<Record<T, number>>
  loadChartData(selectedMetric: T, filterState: F): Promise<ChartDataPayload>
  defaultMetric?: T
}

// eslint-disable-next-line no-restricted-syntax
export enum BlockAssetTypeEnum {
  LOGO = 'brand_logo.evergreen.v1',
  HERO_BANNER = 'hero_banner.evergreen.v1',
  SPOTLIGHT_PRODUCT_COLLECTION = 'spotlight_product_collection.evergreen.v1',
  PRODUCT_COLLECTION = 'product_collection.evergreen.v1',
  PRODUCT_GROUP_COLLECTION = 'product_group_collection.evergreen.v1',
  IN_PAGE_BANNER = 'section_banner.evergreen.v1',
  IMAGE_TEXT = 'image_and_text.evergreen.v1',
}

export interface LargeCarouselListItemProps
  extends Pick<
    GetProductsResponseData['attributes'],
    'upc' | 'productSize' | 'productName' | 'productImageUrl' | 'productId'
  > {
  productRejectionStr?: string
}

type ErrorBlock = {
  [key: string]: string
}

export type ErrorBlocks = ErrorBlock[]

export interface BrandPageFormErrorData extends Omit<BrandPageFormData, 'blocks'> {
  blocks: ErrorBlocks
  blockValues: string
}

export type AssetSizeType = 'desktop' | 'mobile'

export type LinkedBrand = {
  entityId: number
  reportingLevel1: string
  reportingLevel2: string
  reportingBrand: string
}
export interface BlockAttributes {
  id: string
  discriminator: string
  type: string
  assets?:
    | GetAdminBrandPagesIdParamBlockHeroBannerAssets
    | GetAdminBrandPagesIdParamBlockBrandLogoAssets
  content?: GetAdminBrandPagesIdParamBlockImageAndTextContent
  productIds?: Array<string>
  productAssets?: {
    [key: string]: PostBrandPagesIdStatusParamBlockSpotlightProductCollectionProductAssetsValues
  }
}

export interface EditorialStateFieldDecisions {
  id: string
  type: string
  altText?:
    | PostBrandPagesIdStatusParamAssetFieldDecisionsAltText
    | PostBrandPagesIdStatusParamProductAssetFieldDecisionsAltText
  image?:
    | PostBrandPagesIdStatusParamAssetFieldDecisionsImage
    | PostBrandPagesIdStatusParamProductAssetFieldDecisionsImage
  hierarchy?: Array<PostBrandPagesIdStatusParamBrandPageFieldDecisionsHierarchy>
  taxonomy?: { [key: string]: PostBrandPagesIdStatusParamBrandPageFieldDecisionsTaxonomyValues }
  products?: {
    [key: string]: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsProductsValues
  }
  content?: PostBrandPagesIdStatusParamBrandPageBlockFieldDecisionsContent
}

export type Label = {
  id: string
  version: number
}
