import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { useContext } from 'react'
import { useIntl } from 'common'
import { InputText, Select, CardTitle, CardCollapse } from 'components/ids-ads'
import { Label } from 'components/ids-ads/molecules'
import { InputError } from 'components/molecules/InputFields'
import { InfoIcon } from 'components/molecules/InputFields/InfoIcon'
import { AuthContext } from 'context'
import { GenericMessageDescriptor } from 'locales/types'
import { useBillPayerOptions } from 'service/billing'

type LabelOption = {
  value: string
  label: string | number
}

const PURCHASE_ORDER_MAX_LENGTH = 50

const useStyles = () => {
  const theme = useTheme()

  return {
    root: css({
      display: 'grid',
      columnGap: spacing.s24,
      rowGap: spacing.s16,
      gridTemplateColumns: '1fr 1fr',
    }),
    field: css({
      marginTop: spacing.s4,
    }),
    tooltipLabel: css({
      display: 'flex',
      gap: spacing.s4,
      alignItems: 'center',
    }),
    warningText: css({
      color: theme.colors.systemDetrimentalRegular,
      fontSize: '14px',
      marginTop: spacing.s4,
    }),
  }
}

export type BillingCardProps = {
  title?: GenericMessageDescriptor
  handleChange(params: { id: string; value?: LabelOption['value'] }): void
  billPayer: string
  memo: string
  purchaseOrder: string
  billPayerOptions?: LabelOption[]
  optionalLabel?: boolean
  expandOnMount?: boolean
  errors?: {
    billPayer?: string
  }
}

const valueToLabel = (options: LabelOption[], value?: string): LabelOption | undefined =>
  options.find(opt => opt.value === value)

export default function BillingCard({
  handleChange,
  title = 'common.billing',
  memo,
  errors,
  billPayer,
  billPayerOptions,
  purchaseOrder,
  optionalLabel = true,
  expandOnMount = false,
}: BillingCardProps) {
  const { genericFormatMessage: format } = useIntl()
  const { user } = useContext(AuthContext)
  const mandatoryPurchaseOrderAdvertiser = user?.currentAccount?.mandatoryAdvertiserPos
  const mandatoryPurchaseOrderAgency = user?.currentAccount?.mandatoryAgencyPos
  const defaultBillPayerOptions = useBillPayerOptions() as LabelOption[]

  const billPlayerOpts = billPayerOptions ?? defaultBillPayerOptions

  const styles = useStyles()
  const theme = useTheme()

  const billPayerError = errors?.billPayer ? errors.billPayer : ''
  const showPurchaseOrderWarning =
    (billPayer === 'advertiser' && mandatoryPurchaseOrderAdvertiser) ||
    (billPayer === 'agency' && mandatoryPurchaseOrderAgency)

  return (
    <CardCollapse
      expandOnMount={expandOnMount}
      data-testid="billing-card"
      trigger={
        <CardTitle
          css={{ marginBottom: 0 }}
          note={optionalLabel ? format('common.optional') : undefined}
        >
          {format(title)}
        </CardTitle>
      }
    >
      <div css={styles.root} id="billing-card">
        <div data-testid="billPayer-field">
          <Label htmlFor="billPayer">{format('common.billPayer')}</Label>
          <Select<LabelOption>
            styles={{
              control: existingStyles => ({
                ...existingStyles,
                '&, &:hover': {
                  border: billPayerError && `1px solid ${theme.colors.systemDetrimentalRegular}`,
                  backgroundColor: billPayerError && theme.colors.systemDetrimentalLight,
                },
              }),
            }}
            block
            id="billPayer"
            variant="tertiary"
            css={styles.field}
            menuPlacement="auto"
            value={valueToLabel(billPlayerOpts, billPayer)}
            placeholder={format('common.billPayer')}
            options={billPayerOptions ?? defaultBillPayerOptions}
            onChange={e => handleChange({ id: 'billPayer', value: e?.value })}
          />
          {billPayerError && <InputError inputId="billPayer" error={billPayerError} />}
        </div>
        <div>
          <Label htmlFor="memo">{format('common.attention')}</Label>
          <InputText
            id="memo"
            name="memo"
            value={memo}
            css={styles.field}
            data-testid="memo-field"
            placeholder={format('common.billing.memo')}
            onChange={e => handleChange({ id: 'memo', value: e.target.value })}
          />
        </div>
        <div>
          <span css={styles.tooltipLabel}>
            <Label htmlFor="purchaseOrder">{format('common.billing.purchase_order')}</Label>
            <InfoIcon
              info={format({
                id: 'common.billing.mandatoryPurchaseOrderToolTip',
              })}
              infoDescription={format({
                id: 'common.billing.mandatoryPurchaseOrderToolTip',
              })}
              compact
            />
          </span>
          <InputText
            css={styles.field}
            id="purchaseOrder"
            name="purchaseOrder"
            value={purchaseOrder}
            data-testid="purchaseOrder-field"
            placeholder={format('common.billing.purchase_order')}
            maxLength={PURCHASE_ORDER_MAX_LENGTH}
            onChange={e => handleChange({ id: 'purchaseOrder', value: e.target.value })}
          />
          {showPurchaseOrderWarning && !purchaseOrder && (
            <div css={styles.warningText}>{format('pages.campaign.purchaseOrder.warning')}</div>
          )}
        </div>
      </div>
    </CardCollapse>
  )
}
