// This file imports schema definitions from the formats-definitions repo.
// We are importing the files invidually as our version of esbuild (0.14) does not support import globbing.
// Once we upgrade to esbuild 0.19, we can use import globbing to import all the files in the directory.
// https://github.com/evanw/esbuild/blob/main/CHANGELOG.md#0190

// Exchange Configs
import BaseExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/base/manager.json'
import BristolFarmsUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/bristol_farms_us/manager.json'
import InstacartCaExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/instacart_ca/manager.json'
import InstacartUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/instacart_us/manager.json'
import PriceChopperUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/price_chopper_us/manager.json'
import ProviUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/provi_us/manager.json'
import SchnucksUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/schnucks_us/manager.json'
import SproutsUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/sprouts_us/manager.json'
import TheFreshMarketUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/the_fresh_market_us/manager.json'
import ThriveMarketUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/thrive_market_us/manager.json'
import TopsMarketUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/tops_market_us/manager.json'
import UdonCaExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/udon_ca/manager.json'
import UdonUsExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/udon_us/manager.json'
import WoodmansExchangeConfig from 'dependencies/common-definitions/ceviche/exchanges/woodmans_us/manager.json'
// Assets
import BlockHeroBannerLarge from 'dependencies/common-definitions/display/assets/block_hero_banner_large.json'
import BlockHeroBannerMedium from 'dependencies/common-definitions/display/assets/block_hero_banner_medium.json'
import BlockHeroBannerSmall from 'dependencies/common-definitions/display/assets/block_hero_banner_small.json'
import BlockHeroBannerXl from 'dependencies/common-definitions/display/assets/block_hero_banner_xl.json'
import BrandLogoLarge from 'dependencies/common-definitions/display/assets/brand_logo_large.json'
import BrandLogoPromotedAisle from 'dependencies/common-definitions/display/assets/brand_logo_promoted_aisle.json'
import BrandLogoSmall from 'dependencies/common-definitions/display/assets/brand_logo_small.json'
import CaperImageDisplayBanner from 'dependencies/common-definitions/display/assets/caper_image_display_banner.json'
import EmailImagery from 'dependencies/common-definitions/display/assets/email_imagery.json'
import EmailProductImage from 'dependencies/common-definitions/display/assets/email_product_image.json'
import ImageAsideLarge from 'dependencies/common-definitions/display/assets/image_aside_large.json'
import ImageAsideSmall from 'dependencies/common-definitions/display/assets/image_aside_small.json'
import ImageBannerLarge from 'dependencies/common-definitions/display/assets/image_banner_large.json'
import ImageBannerSmall from 'dependencies/common-definitions/display/assets/image_banner_small.json'
import ImageDisplayBannerLarge from 'dependencies/common-definitions/display/assets/image_display_banner_large.json'
import ImageDisplayBannerSmall from 'dependencies/common-definitions/display/assets/image_display_banner_small.json'
import ImageDisplayBannerSquare from 'dependencies/common-definitions/display/assets/image_display_banner_square.json'
import ImageHeroBannerExtraLarge from 'dependencies/common-definitions/display/assets/image_hero_banner_extra_large.json'
import ImageHeroBannerLarge from 'dependencies/common-definitions/display/assets/image_hero_banner_large.json'
import ImageHeroBannerMedium from 'dependencies/common-definitions/display/assets/image_hero_banner_medium.json'
import ImageHeroBannerSmall from 'dependencies/common-definitions/display/assets/image_hero_banner_small.json'
import ImageProductLifestyleLarge from 'dependencies/common-definitions/display/assets/image_product_lifestyle_large.json'
import ImageProductLifestyleSmall from 'dependencies/common-definitions/display/assets/image_product_lifestyle_small.json'
import ImagePromotedAisleHeroBanner from 'dependencies/common-definitions/display/assets/image_promoted_aisle_hero_banner.json'
import ImageSectionBannerSmall from 'dependencies/common-definitions/display/assets/image_section_banner_small.json'
import ImageVideoThumbnail from 'dependencies/common-definitions/display/assets/image_video_thumbnail.json'
import MnmPrototypeVideoAssetThumbnail from 'dependencies/common-definitions/display/assets/mnm.prototype.video_asset_thumbnail.json'
import MnmPrototypeVideoAssetVideoAd from 'dependencies/common-definitions/display/assets/mnm.prototype.video_asset_video_ad.json'
import PsdExample from 'dependencies/common-definitions/display/assets/psd_example.json'
import UvcBannerLarge from 'dependencies/common-definitions/display/assets/uvc_banner_large.json'
import UvcBannerSmall from 'dependencies/common-definitions/display/assets/uvc_banner_small.json'
import Video from 'dependencies/common-definitions/display/assets/video.json'
// Brand Page Blocks
import BrandLogoV1 from 'dependencies/common-definitions/display/brand_page_blocks/brand_logo.evergreen.v1.json'
import HeroBannerV1 from 'dependencies/common-definitions/display/brand_page_blocks/hero_banner.evergreen.v1.json'
import ImageAndTextV1 from 'dependencies/common-definitions/display/brand_page_blocks/image_and_text.evergreen.v1.json'
import ProductCollectionV1 from 'dependencies/common-definitions/display/brand_page_blocks/product_collection.evergreen.v1.json'
import ProductGroupCollectionV1 from 'dependencies/common-definitions/display/brand_page_blocks/product_group_collection.evergreen.v1.json'
import SectionBannerV1 from 'dependencies/common-definitions/display/brand_page_blocks/section_banner.evergreen.v1.json'
import SpotlightProductCollectionV1 from 'dependencies/common-definitions/display/brand_page_blocks/spotlight_product_collection.evergreen.v1.json'
// Brand Pages
import EvergreenV1 from 'dependencies/common-definitions/display/brand_pages/evergreen.v1.json'
import ProductGroupEvergreenV1 from 'dependencies/common-definitions/display/brand_pages/product_group_evergreen.v1.json'
// Creatives
import CaperImageBannerV1 from 'dependencies/common-definitions/display/creatives/caper_image_banner.v1.json'
import ImageBannerV2 from 'dependencies/common-definitions/display/creatives/image_banner.v2.json'
import ImageBannerV3 from 'dependencies/common-definitions/display/creatives/image_banner.v3.json'
import OccasionV1 from 'dependencies/common-definitions/display/creatives/occasion.v1.json'
import PromotedAisleV1 from 'dependencies/common-definitions/display/creatives/promoted_aisle.v1.json'
import PromotedAisleV2 from 'dependencies/common-definitions/display/creatives/promoted_aisle.v2.json'
import PromotedAisleV3 from 'dependencies/common-definitions/display/creatives/promoted_aisle.v3.json'
import PromotedAisleV4 from 'dependencies/common-definitions/display/creatives/promoted_aisle.v4.json'
import SearchBannerV1 from 'dependencies/common-definitions/display/creatives/search_banner.v1.json'
import ShoppableVideoV1 from 'dependencies/common-definitions/display/creatives/shoppable_video.v1.json'
import ShoppableVideoV2 from 'dependencies/common-definitions/display/creatives/shoppable_video.v2.json'
import ShoppableVideoV3 from 'dependencies/common-definitions/display/creatives/shoppable_video.v3.json'
import SmartV1 from 'dependencies/common-definitions/display/creatives/smart.v1.json'
import SponsoredRecipeV1 from 'dependencies/common-definitions/display/creatives/sponsored_recipe.v1.json'
import SponsoredRecipeVideoV1 from 'dependencies/common-definitions/display/creatives/sponsored_recipe_video.v1.json'
import UvcBannerV1 from 'dependencies/common-definitions/display/creatives/uvc_banner.v1.json'
import UvcBannerV2 from 'dependencies/common-definitions/display/creatives/uvc_banner.v2.json'
// Placements
import AskIcSearchInterleavedV1 from 'dependencies/common-definitions/display/placements/ask_ic_search_interleaved.v1.json'
import AskIcSearchTopV1 from 'dependencies/common-definitions/display/placements/ask_ic_search_top.v1.json'
import CaperLocationRecV1 from 'dependencies/common-definitions/display/placements/caper_location_rec.v1.json'
import CaperLocationRecV2 from 'dependencies/common-definitions/display/placements/caper_location_rec.v2.json'
import CaperScreensaverV1 from 'dependencies/common-definitions/display/placements/caper_screensaver.v1.json'
import CarrotAdsCollectionInterleavedV1 from 'dependencies/common-definitions/display/placements/carrot_ads_collection_interleaved.v1.json'
import CarrotAdsCollectionInterleavedV2 from 'dependencies/common-definitions/display/placements/carrot_ads_collection_interleaved.v2.json'
import CarrotAdsCollectionInterleavedV3 from 'dependencies/common-definitions/display/placements/carrot_ads_collection_interleaved.v3.json'
import CarrotAdsCollectionTopV1 from 'dependencies/common-definitions/display/placements/carrot_ads_collection_top.v1.json'
import CarrotAdsCollectionTopV2 from 'dependencies/common-definitions/display/placements/carrot_ads_collection_top.v2.json'
import CarrotAdsSearchInterleavedV1 from 'dependencies/common-definitions/display/placements/carrot_ads_search_interleaved.v1.json'
import CarrotAdsSearchInterleavedV2 from 'dependencies/common-definitions/display/placements/carrot_ads_search_interleaved.v2.json'
import CarrotAdsSearchInterleavedV3 from 'dependencies/common-definitions/display/placements/carrot_ads_search_interleaved.v3.json'
import CarrotAdsSearchTopV1 from 'dependencies/common-definitions/display/placements/carrot_ads_search_top.v1.json'
import CarrotAdsSearchTopV2 from 'dependencies/common-definitions/display/placements/carrot_ads_search_top.v2.json'
import CarrotAdsStorefrontInterleavedV1 from 'dependencies/common-definitions/display/placements/carrot_ads_storefront_interleaved.v1.json'
import CarrotAdsStorefrontInterleavedV2 from 'dependencies/common-definitions/display/placements/carrot_ads_storefront_interleaved.v2.json'
import CarrotAdsStorefrontInterleavedV3 from 'dependencies/common-definitions/display/placements/carrot_ads_storefront_interleaved.v3.json'
import CarrotAdsStorefrontTopV1 from 'dependencies/common-definitions/display/placements/carrot_ads_storefront_top.v1.json'
import CarrotAdsStorefrontTopV2 from 'dependencies/common-definitions/display/placements/carrot_ads_storefront_top.v2.json'
import CollectionInterleavedV1 from 'dependencies/common-definitions/display/placements/collection_interleaved.v1.json'
import CollectionTopV1 from 'dependencies/common-definitions/display/placements/collection_top.v1.json'
import CollectionTopV2 from 'dependencies/common-definitions/display/placements/collection_top.v2.json'
import CollectionTopV3 from 'dependencies/common-definitions/display/placements/collection_top.v3.json'
import CrossRetailerSearchTopV1 from 'dependencies/common-definitions/display/placements/cross_retailer_search_top.v1.json'
import HomeInterleavedV1 from 'dependencies/common-definitions/display/placements/home_interleaved.v1.json'
import HomeInterleavedV2 from 'dependencies/common-definitions/display/placements/home_interleaved.v2.json'
import ItemDetailInterleavedV1 from 'dependencies/common-definitions/display/placements/item_detail_interleaved.v1.json'
import PostCheckoutInterleavedV1 from 'dependencies/common-definitions/display/placements/post_checkout_interleaved.v1.json'
import PreCheckoutInterleavedV1 from 'dependencies/common-definitions/display/placements/pre_checkout_interleaved.v1.json'
import ProviCollectionInterleavedV1 from 'dependencies/common-definitions/display/placements/provi_collection_interleaved.v1.json'
import ProviCollectionInterleavedV2 from 'dependencies/common-definitions/display/placements/provi_collection_interleaved.v2.json'
import ProviCollectionInterleavedV3 from 'dependencies/common-definitions/display/placements/provi_collection_interleaved.v3.json'
import ProviCollectionTopV1 from 'dependencies/common-definitions/display/placements/provi_collection_top.v1.json'
import ProviSearchInterleavedV1 from 'dependencies/common-definitions/display/placements/provi_search_interleaved.v1.json'
import ProviSearchInterleavedV2 from 'dependencies/common-definitions/display/placements/provi_search_interleaved.v2.json'
import ProviSearchInterleavedV3 from 'dependencies/common-definitions/display/placements/provi_search_interleaved.v3.json'
import ProviSearchTopV1 from 'dependencies/common-definitions/display/placements/provi_search_top.v1.json'
import ProviStorefrontInterleavedV1 from 'dependencies/common-definitions/display/placements/provi_storefront_interleaved.v1.json'
import ProviStorefrontInterleavedV2 from 'dependencies/common-definitions/display/placements/provi_storefront_interleaved.v2.json'
import ProviStorefrontInterleavedV3 from 'dependencies/common-definitions/display/placements/provi_storefront_interleaved.v3.json'
import ProviStorefrontTopV1 from 'dependencies/common-definitions/display/placements/provi_storefront_top.v1.json'
import SearchInterleavedV1 from 'dependencies/common-definitions/display/placements/search_interleaved.v1.json'
import SearchInterleavedV2 from 'dependencies/common-definitions/display/placements/search_interleaved.v2.json'
import SearchInterleavedV3 from 'dependencies/common-definitions/display/placements/search_interleaved.v3.json'
import SearchInterleavedV4 from 'dependencies/common-definitions/display/placements/search_interleaved.v4.json'
import SearchInterleavedV5 from 'dependencies/common-definitions/display/placements/search_interleaved.v5.json'
import SearchInterleavedV6 from 'dependencies/common-definitions/display/placements/search_interleaved.v6.json'
import SearchInterleavedV7 from 'dependencies/common-definitions/display/placements/search_interleaved.v7.json'
import SearchTopV1 from 'dependencies/common-definitions/display/placements/search_top.v1.json'
import SearchTopV2 from 'dependencies/common-definitions/display/placements/search_top.v2.json'
import SearchTopV3 from 'dependencies/common-definitions/display/placements/search_top.v3.json'
import StorefrontInterleavedV1 from 'dependencies/common-definitions/display/placements/storefront_interleaved.v1.json'
import StorefrontInterleavedV2 from 'dependencies/common-definitions/display/placements/storefront_interleaved.v2.json'
import StorefrontInterleavedV3 from 'dependencies/common-definitions/display/placements/storefront_interleaved.v3.json'
import StorefrontInterleavedV4 from 'dependencies/common-definitions/display/placements/storefront_interleaved.v4.json'
import StorefrontInterleavedV5 from 'dependencies/common-definitions/display/placements/storefront_interleaved.v5.json'
import StorefrontTopV1 from 'dependencies/common-definitions/display/placements/storefront_top.v1.json'
import UniversalValueCarouselV1 from 'dependencies/common-definitions/display/placements/universal_value_carousel.v1.json'

// Export a loose type for the creatives object.
// We likely loose all typing once we switch to import globbing, so i'm explicitly loosening the typing here.
// The loss of typing isn't a big deal as this module will export typed wrappers anyway.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MapType = { [key: string]: any }

const untypedCreatives = {
  'caper_image_banner.v1': CaperImageBannerV1,
  'image_banner.v2': ImageBannerV2,
  'image_banner.v3': ImageBannerV3,
  'promoted_aisle.v1': PromotedAisleV1,
  'promoted_aisle.v2': PromotedAisleV2,
  'promoted_aisle.v3': PromotedAisleV3,
  'promoted_aisle.v4': PromotedAisleV4,
  'search_banner.v1': SearchBannerV1,
  'shoppable_video.v1': ShoppableVideoV1,
  'shoppable_video.v2': ShoppableVideoV2,
  'shoppable_video.v3': ShoppableVideoV3,
  'smart.v1': SmartV1,
  'sponsored_recipe.v1': SponsoredRecipeV1,
  'sponsored_recipe_video.v1': SponsoredRecipeVideoV1,
  'occasion.v1': OccasionV1,
  'uvc_banner.v1': UvcBannerV1,
  'uvc_banner.v2': UvcBannerV2,
}

// Casting MapType here as typescript mistypes CreativeType if MapType is directly assigned
export const creatives = untypedCreatives as MapType
export type CreativeType = keyof typeof untypedCreatives

export const brandPages: MapType = {
  'evergreen.v1': EvergreenV1,
  'product_group_evergreen.v1': ProductGroupEvergreenV1,
}

export const brandPageBlocks: MapType = {
  'brand_logo.evergreen.v1': BrandLogoV1,
  'hero_banner.evergreen.v1': HeroBannerV1,
  'image_and_text.evergreen.v1': ImageAndTextV1,
  'product_collection.evergreen.v1': ProductCollectionV1,
  'product_group_collection.evergreen.v1': ProductGroupCollectionV1,
  'section_banner.evergreen.v1': SectionBannerV1,
  'spotlight_product_collection.evergreen.v1': SpotlightProductCollectionV1,
}

const untypedAssets = {
  block_hero_banner_large: BlockHeroBannerLarge,
  block_hero_banner_medium: BlockHeroBannerMedium,
  block_hero_banner_small: BlockHeroBannerSmall,
  block_hero_banner_xl: BlockHeroBannerXl,
  brand_logo_large: BrandLogoLarge,
  brand_logo_promoted_aisle: BrandLogoPromotedAisle,
  brand_logo_small: BrandLogoSmall,
  caper_image_display_banner: CaperImageDisplayBanner,
  email_imagery: EmailImagery,
  email_product_image: EmailProductImage,
  image_aside_large: ImageAsideLarge,
  image_aside_small: ImageAsideSmall,
  image_banner_large: ImageBannerLarge,
  image_banner_small: ImageBannerSmall,
  image_display_banner_large: ImageDisplayBannerLarge,
  image_display_banner_small: ImageDisplayBannerSmall,
  image_display_banner_square: ImageDisplayBannerSquare,
  image_hero_banner_extra_large: ImageHeroBannerExtraLarge,
  image_hero_banner_large: ImageHeroBannerLarge,
  image_hero_banner_medium: ImageHeroBannerMedium,
  image_hero_banner_small: ImageHeroBannerSmall,
  image_product_lifestyle_large: ImageProductLifestyleLarge,
  image_product_lifestyle_small: ImageProductLifestyleSmall,
  image_promoted_aisle_hero_banner: ImagePromotedAisleHeroBanner,
  image_section_banner_small: ImageSectionBannerSmall,
  uvc_banner_large: UvcBannerLarge,
  uvc_banner_small: UvcBannerSmall,
  image_video_thumbnail: ImageVideoThumbnail,
  'mnm.prototype.video_asset_thumbnail': MnmPrototypeVideoAssetThumbnail,
  'mnm.prototype.video_asset_video_ad': MnmPrototypeVideoAssetVideoAd,
  psd_example: PsdExample,
  video: Video,
}

export const exchangeConfigs: MapType = {
  base: BaseExchangeConfig,
  bristol_farms_us: BristolFarmsUsExchangeConfig,
  instacart_ca: InstacartCaExchangeConfig,
  instacart_us: InstacartUsExchangeConfig,
  price_chopper_us: PriceChopperUsExchangeConfig,
  provi_us: ProviUsExchangeConfig,
  schnucks_us: SchnucksUsExchangeConfig,
  sprouts_us: SproutsUsExchangeConfig,
  the_fresh_market_us: TheFreshMarketUsExchangeConfig,
  thrive_market_us: ThriveMarketUsExchangeConfig,
  tops_market_us: TopsMarketUsExchangeConfig,
  udon_us: UdonUsExchangeConfig,
  udon_ca: UdonCaExchangeConfig,
  woodmans_us: WoodmansExchangeConfig,
}

// Casting MapType here as typescript mistypes AssetType if MapType is directly assigned
export const assets = untypedAssets as MapType
export type AssetType = keyof typeof untypedAssets

export const placements: MapType = {
  'ask_ic_search_interleaved.v1': AskIcSearchInterleavedV1,
  'ask_ic_search_top.v1': AskIcSearchTopV1,
  'caper_location_rec.v1': CaperLocationRecV1,
  'caper_location_rec.v2': CaperLocationRecV2,
  'caper_screensaver.v1': CaperScreensaverV1,
  'carrot_ads_collection_interleaved.v1': CarrotAdsCollectionInterleavedV1,
  'carrot_ads_collection_interleaved.v2': CarrotAdsCollectionInterleavedV2,
  'carrot_ads_collection_interleaved.v3': CarrotAdsCollectionInterleavedV3,
  'carrot_ads_collection_top.v1': CarrotAdsCollectionTopV1,
  'carrot_ads_collection_top.v2': CarrotAdsCollectionTopV2,
  'carrot_ads_search_interleaved.v1': CarrotAdsSearchInterleavedV1,
  'carrot_ads_search_interleaved.v2': CarrotAdsSearchInterleavedV2,
  'carrot_ads_search_interleaved.v3': CarrotAdsSearchInterleavedV3,
  'carrot_ads_search_top.v1': CarrotAdsSearchTopV1,
  'carrot_ads_search_top.v2': CarrotAdsSearchTopV2,
  'carrot_ads_storefront_interleaved.v1': CarrotAdsStorefrontInterleavedV1,
  'carrot_ads_storefront_interleaved.v2': CarrotAdsStorefrontInterleavedV2,
  'carrot_ads_storefront_interleaved.v3': CarrotAdsStorefrontInterleavedV3,
  'carrot_ads_storefront_top.v1': CarrotAdsStorefrontTopV1,
  'carrot_ads_storefront_top.v2': CarrotAdsStorefrontTopV2,
  'collection_interleaved.v1': CollectionInterleavedV1,
  'collection_top.v1': CollectionTopV1,
  'collection_top.v2': CollectionTopV2,
  'collection_top.v3': CollectionTopV3,
  'cross_retailer_search_top.v1': CrossRetailerSearchTopV1,
  'home_interleaved.v1': HomeInterleavedV1,
  'home_interleaved.v2': HomeInterleavedV2,
  'item_detail_interleaved.v1': ItemDetailInterleavedV1,
  'post_checkout_interleaved.v1': PostCheckoutInterleavedV1,
  'pre_checkout_interleaved.v1': PreCheckoutInterleavedV1,
  'provi_collection_interleaved.v1': ProviCollectionInterleavedV1,
  'provi_collection_interleaved.v2': ProviCollectionInterleavedV2,
  'provi_collection_interleaved.v3': ProviCollectionInterleavedV3,
  'provi_collection_top.v1': ProviCollectionTopV1,
  'provi_search_interleaved.v1': ProviSearchInterleavedV1,
  'provi_search_interleaved.v2': ProviSearchInterleavedV2,
  'provi_search_interleaved.v3': ProviSearchInterleavedV3,
  'provi_search_top.v1': ProviSearchTopV1,
  'provi_storefront_interleaved.v1': ProviStorefrontInterleavedV1,
  'provi_storefront_interleaved.v2': ProviStorefrontInterleavedV2,
  'provi_storefront_interleaved.v3': ProviStorefrontInterleavedV3,
  'provi_storefront_top.v1': ProviStorefrontTopV1,
  'search_interleaved.v1': SearchInterleavedV1,
  'search_interleaved.v2': SearchInterleavedV2,
  'search_interleaved.v3': SearchInterleavedV3,
  'search_interleaved.v4': SearchInterleavedV4,
  'search_interleaved.v5': SearchInterleavedV5,
  'search_interleaved.v6': SearchInterleavedV6,
  'search_interleaved.v7': SearchInterleavedV7,
  'search_top.v1': SearchTopV1,
  'search_top.v2': SearchTopV2,
  'search_top.v3': SearchTopV3,
  'storefront_interleaved.v1': StorefrontInterleavedV1,
  'storefront_interleaved.v2': StorefrontInterleavedV2,
  'storefront_interleaved.v3': StorefrontInterleavedV3,
  'storefront_interleaved.v4': StorefrontInterleavedV4,
  'storefront_interleaved.v5': StorefrontInterleavedV5,
  'storefront_top.v1': StorefrontTopV1,
  'universal_value_carousel.v1': UniversalValueCarouselV1,
}
