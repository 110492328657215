import { SerializedStyles, css as styles } from '@emotion/react'
import { useTheme } from '@instacart/ids-core'
import { TypographyStyle } from '@instacart/ids-core/dist/types/atoms/typography'
import { CSSProperties, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { HovercardTooltip } from '../components/ids-ads/molecules/tooltips/HovercardTooltip'

/** @deprecated use boldSpanFormattingHelper instead */
export const boldTextFormattingHelper = {
  b: (chunks: ReactNode[]) => <span css={{ fontSize: 12, fontWeight: 'bold' }}>{chunks}</span>,
}

export const boldSpanFormattingHelper = {
  b: (chunks: ReactNode[]) => (
    <span style={{ fontWeight: 'bold', fontVariationSettings: `"wght" 700` }}>{chunks}</span>
  ),
}

export const underlineFormattingHelper = {
  u: (chunks: ReactNode[]) => <span style={{ textDecoration: 'underline' }}>{chunks}</span>,
}

export const italicFormattingHelper = {
  i: (chunks: ReactNode[]) => <em style={{ fontStyle: 'italic' }}>{chunks}</em>,
}

export function useIdsSpanFormatter(typography: TypographyStyle) {
  const theme = useTheme()

  return (chunks: ReactNode[]) => <span css={theme.typography[typography]}>{chunks}</span>
}

export const externalLinkFormatter =
  (href: string, style: CSSProperties = {}, css?: SerializedStyles) =>
  (chunks: ReactNode[]) =>
    (
      <a href={href} target="_blank" rel="noopener noreferrer" style={style} css={css}>
        {chunks}
      </a>
    )

export const navLinkFormatter =
  (to: string, css: SerializedStyles, openNewTab = false) =>
  (chunks: ReactNode[]) =>
    (
      <NavLink
        to={to}
        css={css}
        target={openNewTab ? '_blank' : ''}
        rel={openNewTab ? 'noopener noreferrer' : ''}
      >
        {chunks}
      </NavLink>
    )

export const styledSpanFormatter = (css: SerializedStyles) => {
  return (chunks: ReactNode[]) => <span css={css}>{chunks}</span>
}

export const breakFormattingHelper = { br: () => <br /> }

export const listFormattingHelper = (listStyle: CSSProperties['listStyle'] = 'disc') => {
  return {
    ol: (chunks: ReactNode[]) => <ol style={{ listStyle }}>{chunks}</ol>,
    ul: (chunks: ReactNode[]) => <ul style={{ listStyle }}>{chunks}</ul>,
    li: (chunks: ReactNode[]) => <li>{chunks}</li>,
  }
}

export const mailtoLinkFormatter = (href: string) => `mailto:${href}`

export const coachmarkFormattingHelper = {
  br: () => <br />,
  p: (chunks: ReactNode[]) => <p css={styles({ fontWeight: 'normal', marginTop: 0 })}>{chunks}</p>,
}

const tooltipFormattingHelper = (tooltipContent: string) => ({
  tooltip: (chunks: ReactNode[]) => (
    <HovercardTooltip hovercardStateProps={{ placement: 'bottom' }} tooltipContent={tooltipContent}>
      <span css={{ textDecoration: 'underline' }}>{chunks}</span>
    </HovercardTooltip>
  ),
})

export interface BuildFormattingParams {
  useListFormatting?: boolean | CSSProperties['listStyle']
  useBoldSpanFormatting?: boolean
  useBreakFormatting?: boolean
  useExternalLinkFormatting?: string
  useTooltipLinkFormatting?: string
}

export const buildFormatting = (props: BuildFormattingParams) => {
  let formatting = {}

  if (props.useBreakFormatting) formatting = { ...formatting, ...breakFormattingHelper }
  if (props.useBoldSpanFormatting) formatting = { ...formatting, ...boldSpanFormattingHelper }
  if (props.useListFormatting) {
    const listStyle = props.useListFormatting === true ? undefined : props.useListFormatting
    formatting = { ...formatting, ...listFormattingHelper(listStyle) }
  }
  if (props.useExternalLinkFormatting) {
    formatting = { ...formatting, link: externalLinkFormatter(props.useExternalLinkFormatting) }
  }
  if (props.useTooltipLinkFormatting) {
    formatting = { ...formatting, ...tooltipFormattingHelper(props.useTooltipLinkFormatting) }
  }

  return formatting
}
