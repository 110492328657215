import { useFormikContext } from 'formik'
import BillingCard, { BillingCardProps } from './BillingCard'

type FormikWrappedBillingCardValues = {
  purchaseOrder: string
  memo: string
  billPayer: string
}

export type FormikWrappedBillingCardProps = Pick<
  BillingCardProps,
  'title' | 'billPayerOptions' | 'expandOnMount' | 'optionalLabel'
>

export default function FormikWrappedBillingCard({
  title,
  optionalLabel = true,
  expandOnMount = false,
  billPayerOptions,
}: FormikWrappedBillingCardProps) {
  const { setFieldValue, values, errors } = useFormikContext<FormikWrappedBillingCardValues>()

  return (
    <BillingCard
      title={title}
      errors={errors}
      memo={values.memo}
      optionalLabel={optionalLabel}
      expandOnMount={expandOnMount}
      billPayer={values.billPayer}
      purchaseOrder={values.purchaseOrder}
      billPayerOptions={billPayerOptions}
      handleChange={value => setFieldValue(value.id, value.value)}
    />
  )
}
