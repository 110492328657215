// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPage } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPage'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPage'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStates } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStates'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStates'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategy } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategy'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategy'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
 */
export interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributes {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  name: string
  /**
   * The maximum amount, in dollars, you want to pay per thousands views for advertised creatives in this ad group.
   * @type {number}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  defaultBid?: number
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  campaignId: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  placementType: string
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  creative: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreative
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategy}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  targetingStrategy: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategy
  /**
   * Lock key for optimistic locking. Lock key retrieved in the response should be passed in the request. Only for updates
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  lockKey?: string
  /**
   * Enabled flag for the ad group. Ad group is disabled/paused if set to false.
   * @type {boolean}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  enabled?: boolean
  /**
   * Ad group status
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  status?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesStatusEnum
  /**
   * Timestamp of when the ad group was created
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  createdAt?: string
  /**
   * Timestamp of when the ad group was updated
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  updatedAt?: string
  /**
   * Editorial states
   * @type {Array<GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStates>}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  editorialStates?: Array<GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStates>
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPage}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributes
   */
  brandPage?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPage
}

/**
 * @export
 */
export const GetDisplayCampaignsParamDataAttributesAdGroupsAttributesStatusEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Editable: 'EDITABLE',
  Ended: 'ENDED',
  Escalated: 'ESCALATED',
  InReview: 'IN_REVIEW',
  OutOfBudget: 'OUT_OF_BUDGET',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type GetDisplayCampaignsParamDataAttributesAdGroupsAttributesStatusEnum =
  typeof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesStatusEnum[keyof typeof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesStatusEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsParamDataAttributesAdGroupsAttributes interface.
 */
export function instanceOfGetDisplayCampaignsParamDataAttributesAdGroupsAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'campaignId' in value
  isInstance = isInstance && 'placementType' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributes {
  return GetDisplayCampaignsParamDataAttributesAdGroupsAttributesFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
    campaignId: json['campaign_id'],
    placementType: json['placement_type'],
    creative: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeFromJSON(
      json['creative']
    ),
    targetingStrategy:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyFromJSON(
        json['targeting_strategy']
      ),
    lockKey: !exists(json, 'lock_key') ? undefined : json['lock_key'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    status: !exists(json, 'status') ? undefined : json['status'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    editorialStates: !exists(json, 'editorial_states')
      ? undefined
      : (json['editorial_states'] as Array<any>).map(
          GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesFromJSON
        ),
    brandPage: !exists(json, 'brand_page')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageFromJSON(
          json['brand_page']
        ),
  }
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    default_bid: value.defaultBid,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeToJSON(
      value.creative
    ),
    targeting_strategy:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesTargetingStrategyToJSON(
        value.targetingStrategy
      ),
    lock_key: value.lockKey,
    enabled: value.enabled,
    status: value.status,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    editorial_states:
      value.editorialStates === undefined
        ? undefined
        : (value.editorialStates as Array<any>).map(
            GetDisplayCampaignsParamDataAttributesAdGroupsAttributesEditorialStatesToJSON
          ),
    brand_page: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesBrandPageToJSON(
      value.brandPage
    ),
  }
}
